@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Comfortaa', cursive;
    color: white;
}

.black {
    color: #8CCB92;
}

@layer base {
    html {
      @apply scroll-smooth;
      @apply scrollbar-none;
      @apply scrollbar-thumb-jade;
      @apply scrollbar-thumb-rounded-lg;
      overflow: auto;
    }
}
